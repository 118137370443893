import { Controller } from 'stimulus'; 
export default class extends Controller {
      static targets = ["modalarea", "panel"]

  createWrapper() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('animate__animated', 'animate__slideInDown')
    wrapper.setAttribute('data-vidmodal-target', 'panel')
    return wrapper
  }

    
  load({ params }) {
    const wrapper = this.createWrapper()
    this.modalareaTarget.innerHTML = wrapper.outerHTML
    fetch(params.url)
      .then(response => response.text())
      .then(html => this.panelTarget.innerHTML = html)
    }
  
  fecha() {
      this.panelTarget.innerHTML = ""
    }
  


}  