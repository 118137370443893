import { Controller } from 'stimulus'; 
export default class extends Controller {

    static targets = ["name", "output"]
    connect() {
        console.log("hello from StimulusJS")
      }
  greet() {
    const element = this.nameTarget
    const name = element.value
    // window.location.reload()
    console.log(`hello, ${name}!`)
    this.outputTarget.textContent = `hello, ${name}!`

  }


}