// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("turbolinks").start()
//import "@hotwired/turbo-rails"
//require("jquery-ui")
//require("@rails/activestorage").start()
//require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"

Turbo.session.drive = false

Rails.start()
ActiveStorage.start()

document.addEventListener('turbo:before-fetch-request', (event) => {
  // Turbo Drive does not send a referrer like turbolinks used to, so let's simulate it here
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = $("meta[name='csp-nonce']").prop('content')
})
document.addEventListener("turbo:before-cache", function() {
  let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
  for (var element of scriptTagsToAddNonces) {
    element.setAttribute('nonce', element.nonce);
  }
});
document.addEventListener("turbo:before-cache", function() {
  let linkTagsToAddNonces = document.querySelectorAll("link[nonce]");
  for (var element of linkTagsToAddNonces) {
    element.setAttribute('nonce', element.nonce);
  }
});

import 'controllers'
import 'bootstrap/dist/js/bootstrap'
import 'scripts/cookieconsent.js'
import 'scripts/jquery.js'

global.$ = require('jquery')
global.jQuery = require('jquery')

import 'scripts/jquery-mask.js'

import 'bootstrap/dist/css/bootstrap'
import 'lightgallery/css/lightgallery.css'    
import "stylesheets/application"
import 'swiper/swiper.min.css'
import 'stylesheets/bootstrap-icons'
import 'stylesheets/cookieconsent.css' 
import 'animate.css'
import 'stylesheets/swiper.css'
import 'mapbox-gl/dist/mapbox-gl'
import "controllers"
