import { Controller } from 'stimulus'
import mapboxgl from '!mapbox-gl'
export default class extends Controller {
static targets = ['map', 'wrapper', 'locais']


  connect() {
    this.map = null
    this.markers = null
    this.initMapbox()
  }


 initMapbox() {
    const mapElement = document.getElementById('map')
    const geojson = JSON.parse(mapElement.dataset.markers)
    console.log(geojson)
    //mapboxgl.accessToken = this.wrapperTarget.dataset.mapboxApiKey
    mapboxgl.accessToken = "pk.eyJ1Ijoia2VudGFyb2Z1aml5IiwiYSI6ImNqYzJjemZkMDBkZDIzNHI1MTdvOHE0eGgifQ.lFB0RUWtS_pJQR9Ne4QlFA"
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 2,
      center: [ '-55.822', '-16.468']
    })

  //  currentInstance = this

    geojson.filiais.forEach((marker) => {
      this.addMarker(marker)
    })
  }
 

  addMarker(marker) {
    new mapboxgl.Marker()
      .setLngLat(marker.geometry.coordinates)
      .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                '<h6>' +
                  marker.properties.nome +
                  '</h6><p>' +
                  marker.properties.endereco  +
                  '</p>'
          ))
      .addTo(this.map)
  }
}

