import { Controller } from 'stimulus'; 
export default class extends Controller {
      static targets = ["menuarea", "panel","leveldois", "acionador"]

  createWrapper() {
    const wrapper = document.createElement('div')
    wrapper.setAttribute('data-navigation-target', 'panel')
    return wrapper
  }

    
  load({ params }) {
    this.checkAppearance();
    const mobbusca = document.querySelector('#mobbusca')
    mobbusca.classList.add('opacity-0')
    const wrapper = this.createWrapper()
    this.menuareaTarget.innerHTML = wrapper.outerHTML
    fetch(params.url)
      .then(response => response.text())
      .then(html => this.panelTarget.innerHTML = html)
  }
  
  checkAppearance() {
     const trig = document.querySelector('#mobmenu_trigger');
     if (this.hasPanelTarget) {
      console.log("aberto")
      
    } else {    
      console.log("fechado")
      trig.classList.toggle('cross')
       this.toggleUnder()
       this.acionadorTarget.setAttribute('data-action', 'click->navigation#fecha')

    }

  }
  
  fecha() {
    const trig = document.querySelector('#mobmenu_trigger')
           this.acionadorTarget.setAttribute('data-action', 'click->navigation#load')
    trig.classList.toggle('cross')
        const mobbusca = document.querySelector('#mobbusca')
    mobbusca.classList.remove('opacity-0')
     this.toggleUnder()
    this.panelTarget.remove()
    }
  

  
  toggleUnder() {
    const under = document.querySelector('#undermenu');
    under.classList.toggle("cobretudo")
  }

}  