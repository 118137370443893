import { Controller } from 'stimulus'; 
export default class extends Controller {

    static targets = ["name", "formtitulo", "categorias", "output", "postbtns", "partmateria", "partpodcast", "partrevista", "partvideo", "outtipo", "outcat", "outautor", "catpod"]
    connect() {
        console.log("hello from StimulusJS")
        const ftit = 'Selecione o tipo de conteúdo'
        this.formtituloTarget.textContent = ftit
      }
  greet() {
    const element = this.nameTarget
    const name = element.value
    
    // window.location.reload()
    console.log(`hello, ${name}!`)
    this.outputTarget.textContent = `hi, ${name}!`
    

  }
  
   materia() {
    const tiponome = 'Materia'
   // const catid = 6
    const ftit = 'Nova matéria'
    this.formtituloTarget.textContent = ftit
    this.postbtnsTarget.classList.add('hidden')
    this.partmateriaTarget.classList.remove('hidden')
    this.partpodcastTarget.remove()
    this.partrevistaTarget.remove()
    this.partvideoTarget.remove()
    this.outtipoTarget.value = `${tiponome}`
  //   this.outcatTarget.value = `${catid}`
    this.outautorTarget.value = `${tiponome}`
    
  }
     podcast() {
    const tiponome = 'Podcast'
    //const catid = 12
    const ftit = 'Novo podcast'
    console.log("chamou catpod")  
    this.formtituloTarget.textContent = ftit
    this.postbtnsTarget.classList.add('hidden')
    this.partpodcastTarget.classList.remove('hidden')
    this.partmateriaTarget.remove()
    this.partrevistaTarget.remove()
    this.partvideoTarget.remove()
    this.outtipoTarget.value = `${tiponome}`
    //this.outcatTarget.value = `${catid}`
    this.outautorTarget.value = `${tiponome}`
  }
    revista() {
    const tiponome = 'Revista'
    const catid = 9
    const ftit = 'Nova revista'
    this.formtituloTarget.textContent = ftit
    this.postbtnsTarget.classList.add('hidden')
    this.partrevistaTarget.classList.remove('hidden')
    this.partmateriaTarget.remove()
    this.partpodcastTarget.remove()
    this.partvideoTarget.remove()
    this.outtipoTarget.value = `${tiponome}`
    //this.outcatTarget.value = `${catid}`
    this.outautorTarget.value = `${tiponome}`
  }
    video() {
    const tiponome = 'Video'
    const catid = 8
    const ftit = 'Novo video'
    this.formtituloTarget.textContent = ftit
    this.postbtnsTarget.classList.add('hidden')
    this.partvideoTarget.classList.remove('hidden')
    this.partmateriaTarget.remove()
    this.partpodcastTarget.remove()
    this.partrevistaTarget.remove()
    this.outtipoTarget.value = `${tiponome}`
   // this.outcatTarget.value = `${catid}`
    this.outautorTarget.value = `${tiponome}`
  }
  



}