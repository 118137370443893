import { Controller } from 'stimulus'; 
import { get } from '@rails/request.js';
export default class extends Controller {
 
    static targets = ['citystate'];
    change(event) {
        let state = event.target.selectedOptions[0
        ].value;
        let cityFieldId = event.currentTarget.dataset.citystateTarget;
        let cityField = this[`${cityFieldId}Target`];

        get(`/cities_by_state/contato_citystate_id/${state}`, {
            responseKind: 'turbo-stream'
        }).then((data) => {
            console.log(data);
        });
    }

}   
