/* globals daterangepicker, $ */
"use strict";

import { Controller } from 'stimulus'; 
import { get } from '@rails/request.js';
import moment from 'moment';
import daterangepicker from "packs/daterangepicker.js";

export default class extends Controller {
 
    static targets = ['arquivos', 'source', 'filterable', 'date_range', 'applybtn', 'overlay'];
    unidadelist = [];
    temaslist = [];
    datasList = [];
    arquivoslist = [];


    connect() {
        this.arquivoslist = this.filterableTargets
        this.arquivoslist = this.arquivoslist
        if(this.date_rangeTarget) {
           this.selectedRange = false;
            $(this.date_rangeTarget).daterangepicker({
                parentEl: "#datecont",
                opens: 'bottom',
                showCustomRangeLabel: true,
                alwaysShowCalendars: true,
                autoApply: true,
                ranges: {
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últimos 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                },
                locale: {
        "format": "DD/MM/YYYY",
        "separator": " - ",
        "applyLabel": "Aplicar filtro",
        "cancelLabel": "Fechar",
        "fromLabel": "De",
        "toLabel": "Até",
        "customRangeLabel": "Período",
        "daysOfWeek": [
            "D",
            "S",
            "T",
            "Q",
            "Q",
            "S",
            "S"
        ],
        "monthNames": [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        "firstDay": 1
    },
            }).on('apply.daterangepicker', function (e, picker) {
                let datas = picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY');
                this.selectedRange = datas;
                if (this.selectedRange) {
                    picker.show();
                    this.selectedRange = false;
                }
            });
            $(".ranges ul li").on("click", function() {
                this.selectedRange = $(this).text();
            }); 
            $('.applyBtn').on('click', () => {
                console.log("chamou applybtn");
                this.changedatas();
                picker.show();

            });
        }
    }

  

    changeunidades(event) {
        this.getunidades(event);
    }

    changetema(event) {
        this.gettemas(event);
    }   

    changedatas() {
        this.getdatas();
    }



    gettemas(event) {
        this.temaslist.push(event.target.attributes.value.value);
        console.log("temas");
        console.log(this.temaslist);
        let unique = this.temaslist.filter((v, i, a) => a.indexOf(v) === i)
        console.timeLog("unique");
        console.log(unique)
        let temas = Array.from(unique)
        console.log(temas)
        this.temaslist = temas
        //this.gettemadata(temas);
    }

    buscatemas() {
        console.log("clicou temas");
        console.log(this.temaslist)
        this.gettemadata(this.temaslist);
    }

    gettemadata(temas) {
    //    const buscaform = document.getElementById('busca');
        get(`/busca_empresa_tema?temasselecionados=${temas}`, {
            responseKind: 'html'
        }).then((data) => {
            console.log(data);
        });

    }

    getdatas() {
        this.datasList = [];
        const buscadatas = document.querySelector('#buscadatas');
                console.log("valor buscadadas");
                    console.log(buscadatas.value);
       this.datasList.push(buscadatas.value);
        ///console.log("datas");
        console.log(this.datasList);
      //  this.getdatasdata(this.datasList);

    }

    buscadatas() {
        console.log("clicou buscadatas");
        console.log(this.datasList)
        this.getdatasdata(this.datasList);
    }

    getdatasdata(datas) {
        get(`/busca_empresa_datas?datasselecionadas=${datas}`, {
            responseKind: 'turbo-stream'
        }).then((data) => {
            console.log(data);
        });
    }


    getunidades(event) {
        this.unidadelist.push(event.target.attributes.value.value);
        console.log(this.unidadelist);
        let unique = this.unidadelist.filter((v, i, a) => a.indexOf(v) === i)
        console.log("unique")
        console.log(unique)
        let unidades = Array.from(unique)
        console.log("unidades")
        console.log(unidades)
        this.unidadelist = unidades
       // this.getdata(unidades);
    }

    buscaunidades() {
        console.log("clicou unidades");
        console.log(this.unidadelist)
        this.getdataunidades(this.unidadelist);
    }




    getdataunidades(unidades) {
       // const buscaform = document.getElementById('busca');
        get(`/busca_empresa_unidade?unidadesselecionadas=${unidades}`, {
            responseKind: 'turbo-stream'
        }).then((data) => {
            console.log(data);
        });
    } 

    filter(event) {
        //  let lista = Arrray.from(this.filterableTargets);
        // console.log("lista")
        //console.log(lista)
        //lista.forEach((element, index) => {
        //    arquivoslist.push(element)
        //    console.log("arquivoslist")
        //    console.log(arquivoslist)
       // }) 
        console.log("filterabletargets no arquivoslist")
        console.log(this.arquivoslist)     
        console.log("chamou filter")
        console.log("filterabletargets com this")
        console.log(this.filterableTargets)
        console.log("temaslist inicio")
        console.log(this.temaslist)
        let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
        console.log(lowerCaseFilterTerm);

        this.arquivoslist.forEach((el, i) => {
            console.log("el")
            console.log(el)
            console.log("i")
            console.log(i)
            let filterablekeys = []
            filterablekeys.push(el.getAttribute("data-buscaempresa-key"))
              console.log("filterablekeys")
            console.log(filterablekeys)
           // el.classList.toggle("filter--notFound", !filterableKey(lowerCaseFilterTerm))
            el.classList.add("filter--notFound", filterablekeys.some(r=> this.temaslist.includes(r))  )
            
            
        })
    }


    rangeSelected(e, picker) {  
        console.log("rangeSelected");
    } 
    submit(event) {
        console.log("click")
        this.element.submit()
    }

    toggleOverlay() {
        this.overlayTarget.classList.toggle('show');
    }

}   