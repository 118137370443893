import { Controller } from 'stimulus'; 
export default class extends Controller {

    static tableClasses = ['table', 'table-bordered', 'border-secondary', 'table-striped', 'table-responsive']
    connect() {
        console.log("hello from StimulusJS")
        this.estiliza()
      }
      
    createWpapper() {
        const wrapper = document.createElement('div')
        wrapper.classList.add('table-responsive')
        return wrapper
        }
    
    estiliza() {
        const tables = document.getElementsByTagName('table')
        for (var table of tables) {
            table.classList.add(...this.constructor.tableClasses)
        }
        const esquerdas = document.querySelectorAll("tr td:first-child")
              for (var esquerda of esquerdas) {
                  esquerda.classList.add('w-35')
              }
      }

}