import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {
    static targets = ["toggleicon"]


  toggle() {
      super.toggle()
      this.toggleiconTarget.classList.toggle('bi-chevron-right');
       this.toggleiconTarget.classList.toggle('bi-chevron-left')
  }
}