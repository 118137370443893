import { Controller } from 'stimulus'
import mapboxgl from '!mapbox-gl'
export default class extends Controller {
static targets = ['map', 'wrapper', 'locais']


  connect() {
    this.map = null
    this.markers = null
    this.initMapbox()
  }


 initMapbox() {
    const mapElement = document.getElementById('map')
    const geojson = JSON.parse(mapElement.dataset.markers)
    mapboxgl.accessToken = this.wrapperTarget.dataset.mapboxApiKey
    /*
   let geojson = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -50.439,
          -21.2079
        ]
      },
      "properties": {
        "nome": "Araçatuba",
        "lat": -50.439,
        "lon": -21.2079
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -44.084,
          -19.9132
        ]
      },
      "properties": {
        "nome": "Contagem",
        "lat": -19.9132,
        "lon": -44.084
      }
    }
  ]
}
*/
let initlat, initlon;
//if (geojson.filiais.features.length > 0) {
console.log(geojson.filiais[0].geometry.coordinates);
  initlat = geojson.filiais[0].geometry.coordinates[0];
  initlon = geojson.filiais[0].geometry.coordinates[1];
//}
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 13,
      center:  geojson.filiais[0].geometry.coordinates 
    })

  //  currentInstance = this
    geojson.filiais.forEach((marker) => {
      this.addMarker(marker)
    })

  }
 

  addMarker(marker) {
    new mapboxgl.Marker()
      .setLngLat(marker.geometry.coordinates)
      .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                '<a href=' + marker.properties.url + '>' +
                '<h5>' +
                  marker.properties.nome +
                  '</h5><p>' +
                  marker.properties.cidade + ' - ' + marker.properties.estado +
                  '</p></a>'
          ))
      .addTo(this.map)
  }
}