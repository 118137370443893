import { Controller } from "stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
    static targets = ["input"];

    initialize() {
        var str = "(00) 000000000";
        var telefone = document.getElementById("contato_telefone").value;
        console.log(telefone);
    

        Inputmask({
            regex: "\\(\\d{2}\\)\\d{5}-\\d{4}",
            placeholder: str,
            clearMaskOnLostFocus: false,
        }).mask(this.inputTarget);
    }

    getPhone() {
        var value = document.getElementById("inputmaskInput").value;
        var telefone = value;
        document.getElementById("contato_telefone").value = telefone;
    }

}