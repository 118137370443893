import { Controller } from 'stimulus'; 
import { get } from '@rails/request.js';
export default class extends Controller {
 
    static targets = ['city'];
    change(event) {
        let state = event.target.selectedOptions[0].value;
        let target = this.cityTarget.id;

        get(`/cities_by_state/${target}/${state}`, {
            // this.populateCities(data);
            responseKind: 'turbo-stream'
        }).then((data) => {
            console.log(data);
        });
    }

}   