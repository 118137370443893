import { Controller } from 'stimulus'; 
export default class extends Controller {
      static targets = ["menuarea", "panel","leveldois"]

  createWrapper() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('animate__animated', 'animate__slideInDown')
    wrapper.setAttribute('data-system-target', 'panel')
    return wrapper
  }

    
  load({ params }) {
   // this.animateMenu();
    this.toggleUnder();
    const wrapper = this.createWrapper()
    this.menuareaTarget.innerHTML = wrapper.outerHTML
    fetch(params.url)
      .then(response => response.text())
      .then(html => this.panelTarget.innerHTML = html)
    }
  
  fecha() {
   //  this.animateMenu();
     this.toggleUnder();
    this.panelTarget.innerHTML = ""
    const modal = document.querySelector('#exampleModal')
    modal.hide()
    }
  
  desce() {
    this.panelTarget.classList.add("animate__slideInDown")
  }

  sobe() {
    this.panelTarget.classList.add("animate__slideOutUp") 
  }

  vai() {
    this.leveldoisTarget.classList.add("animate__slideInRight")
  }

  volta() {
    //this.panelTarget.classList.add("animate__slideInLeft")
    this.animateCSS('#usethis', 'bounce');

  }
  
  toggleUnder() {
    const under = document.querySelector('#undermenu');
    under.classList.toggle("cobretudo")
  }
  
  animateMenu() {
    const trig = document.querySelector('#mobmenu_trigger');
    if(trig.classList.contains('cross')){
      console.log('tem cross')}
      
    trig.classList.toggle('cross')
}  

}