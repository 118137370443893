import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [ "arquivousuarios" ]

  connect() {
    console.log("conectado") 
    this.subscription = consumer.subscriptions.create({ channel: "FavoritosChannel", id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    console.log("recebido")
    console.log(data)
    this.arquivousuariosTarget.innerHTML = data.arquivo
  }
}