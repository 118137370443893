import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import HelloController from "./hello_controller.js"
import PostController from "./post_controller.js"
import Lightbox from 'stimulus-lightbox'
import Reveal from 'stimulus-reveal-controller'
import NestedForm from 'stimulus-rails-nested-form'
import Sortable from 'stimulus-sortable'
import DragController from './drag_controller.js'
import ListsController from './lists_controller.js'
import ResetFormController from './reset_form_controller.js'
import CharacterCounter from 'stimulus-character-counter'
import PasswordVisibility from 'stimulus-password-visibility'
import Carousel from 'stimulus-carousel'
import SystemController from './system_controller.js'
import VidmodalController from './vidmodal_controller.js'
import Notification from 'stimulus-notification'
import HubsidebarController from './hubsidebar_controller.js'
import NavigationController from './navigation_controller.js'
import ProdutoController from './produto_controller.js'
import HourController from './hour_controller.js'
import PhoneController from './phone_controller.js'
import CitystateController from './citystate_controller.js'
import Prefetch from 'stimulus-prefetch'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import BuscaempresaController from './buscaempresa_controller.js'
import FavoritosController from './favoritos_controller.js'
import { Autocomplete } from 'stimulus-autocomplete'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from '../controllers/application_controller'
import MapboxController from './mapbox_controller.js'
import MapboxdetailController from './mapboxdetail_controller.js'
import CitystatedoubleController from '../controllers/citystatedouble_controller.js'
import MapboxdetailponeController from './mapboxdetailpone_controller.js'
import Mapboxdetail0Controller from './mapboxdetailp0_controller.js'
import Mapboxdetail1Controller from './mapboxdetailp1_controller.js'
import Mapboxdetail2Controller from './mapboxdetailp2_controller.js'
import MapboxpredicController from  './mapboxpredic_controller.js'
// import DropzoneController from './dropzone_controller.js'


const application = Application.start()
const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))

application.register("hello", HelloController)
application.register("post", PostController)
application.register('lightbox', Lightbox)
application.register('reveal', Reveal)
application.register('nested-form', NestedForm)
application.register('sortable', Sortable)
application.register('drag', DragController)
application.register('lists', ListsController)
application.register('reset-form', ResetFormController)
application.register('password-visibility', PasswordVisibility)
application.register('character-counter', CharacterCounter)
application.register('carousel', Carousel)
application.register('system', SystemController)
application.register('vidmodal', VidmodalController)
application.register('notification', Notification)
application.register('hubsidebar', HubsidebarController)
application.register('navigation', NavigationController)
application.register('produto', ProdutoController)
application.register('hour', HourController)
application.register('phone', PhoneController)
application.register('citystate', CitystateController)
application.register('prefetch', Prefetch)
application.register('checkbox-select-all', CheckboxSelectAll)
application.register('buscaempresa', BuscaempresaController)
application.register('favoritos', FavoritosController)
application.register('autocomplete', Autocomplete)
application.register('mapbox', MapboxController)
application.register('mapboxdetail', MapboxdetailController)
application.register('citystatedouble', CitystatedoubleController)
application.register('mapboxdetailpone', MapboxdetailponeController)
application.register('mapboxdetailp0', Mapboxdetail0Controller)
application.register('mapboxdetailp1', Mapboxdetail1Controller)
application.register('mapboxdetailp2', Mapboxdetail2Controller)
application.register('mapboxpredic', MapboxpredicController)
// application.register('dropzone', MeController)
StimulusReflex.initialize(application, { consumer, controller, isolate: true })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'
